import PropTypes from "prop-types";
import { Button, Icon } from "components/basic";

function TwitterShare({ href, onShare }) {
  function openInNewTab(url) {
    window.open(url, "blank").focus();
    onShare("twitter");
  }

  return (
    <Button
      className="inline-flex min-h-38 items-center justify-start rounded-5 bg-blue-400 px-10 py-5 text-14 text-white-100"
      onClick={() => {
        openInNewTab(`https://twitter.com/share?url=${href || window.location.href}`);
      }}
    >
      <Icon icon="twitter" className="mr-10 h-22 w-22" />
      <span className="mr-auto mt-2">Share on Twitter</span>
    </Button>
  );
}

TwitterShare.propTypes = {
  href: PropTypes.string,
  onShare: PropTypes.func.isRequired,
};

TwitterShare.defaultProps = {
  href: null,
};

export default TwitterShare;
