import Avatar from "components/Avatar";
import { Icon, Link, Button } from "components/basic";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { AVATAR_SIZE_PREFIX, getProfileUrl, AUTH_MODAL_STATE, REG_PROMPT_TRIGGERS, ENTITY_TYPES } from "const";
import { useStore } from "store";
import PropTypes from "prop-types";
// import Popup from "components/popups";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import ShareButtons from "components/ShareButtons";
import LikeButton from "components/LikeButton";
import { useState } from "react";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

// const popperOptions = {
//   strategy: "fixed",
//   placement: "bottom",
//   modifiers: [
//     {
//       name: "preventOverflow",
//       options: {
//         padding: 10,
//       },
//     },
//     {
//       name: "offset",
//       options: {
//         offset: [0, 10],
//       },
//     },
//   ],
// };

function CardHeader(props) {
  const {
    ownerProfile,
    publishedAt,
    publishDateText,
    editable,
    editFn,
    blogpostId,
    removable,
    isDraft,
    pinned,
    likes,
    shareId,
    shareType,
    removableCb,
  } = props;

  const [isDeleteRequested, setDeleteRequested] = useState(false);

  const { pages, profiles, user, blogposts, ui } = useStore();

  if (pinned)
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col md:flex-row md:items-center">
          {ownerProfile && (
            <div className="flex items-center">
              <Link
                href={getProfileUrl(ownerProfile.id, ownerProfile.username)}
                beforeNav={() => pages.profilePage.mount(ownerProfile.id, ownerProfile.name)}
                rel="canonical"
              >
                <Avatar profile={ownerProfile} size={AVATAR_SIZE_PREFIX.EXTRA_SMALL} scale={90} />
              </Link>
              <Link
                href={getProfileUrl(ownerProfile.id, ownerProfile.username)}
                beforeNav={() => pages.profilePage.mount(ownerProfile.id, ownerProfile.username)}
                className="ml-10 text-15 font-semibold text-new-gray-800 hover:text-new-blue-100"
                rel="canonical"
              >
                {ownerProfile.name || ownerProfile.username}
              </Link>
            </div>
          )}
          {publishedAt && (
            <p className={cn("text-gray-1200", ownerProfile && "mt-5 md:ml-40 md:mt-0")}>
              <span>{publishDateText}</span> <span>{dayjs.utc(publishedAt).local().format("LL HH:mm")}</span>
            </p>
          )}
        </div>
        <div className="flex min-w-[7rem] flex-col md:flex-row">
          <div className="flex flex-shrink-0 items-center self-start text-new-blue-100 md:self-center">
            <Icon icon="pin" className="mr-10 h-13 w-13" />
            <span className="text-13 font-medium uppercase">pinned</span>
          </div>
          {editable && editFn && (
            <Button
              className="mt-10 flex-shrink-0 rounded-6 p-10 text-13 shadow-page-title transition-colors hover:bg-new-blue-100/60 hover:text-white-100 md:order-first md:mr-10 md:mt-0"
              onClick={editFn}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    );

  return (
    <div className="flex w-full items-start justify-between md:items-center">
      <div className={cn("md:w-auto md:items-center", !ownerProfile && "self-center", isDraft ? "flex" : "md:flex")}>
        {isDraft && (
          <div className="mr-10 flex items-center self-stretch rounded-6 bg-yellow-200 px-10 font-semibold">DRAFT</div>
        )}
        {ownerProfile && (
          <div className="flex items-center">
            <Link
              href={getProfileUrl(ownerProfile.id, ownerProfile.username)}
              beforeNav={() => pages.profilePage.mount(ownerProfile.id, ownerProfile.name)}
              rel="canonical"
            >
              <Avatar profile={ownerProfile} size={AVATAR_SIZE_PREFIX.EXTRA_SMALL} scale={90} />
            </Link>
            <Link
              href={getProfileUrl(ownerProfile.id, ownerProfile.username)}
              beforeNav={() => pages.profilePage.mount(ownerProfile.id, ownerProfile.username)}
              className="ml-10 text-15 font-semibold text-new-gray-800 hover:text-new-blue-100"
              rel="canonical"
            >
              {ownerProfile.name || ownerProfile.username}
            </Link>
          </div>
        )}
        {publishedAt && (
          <p className={cn("text-gray-1200", ownerProfile && "mt-5 md:ml-40 md:mt-0")}>
            <span>{publishDateText}</span> <span>{dayjs.utc(publishedAt).local().format("LL HH:mm")}</span>
          </p>
        )}
      </div>
      <div className="hidden items-center space-x-10 md:flex">
        {user.isLoggedIn ? (
          likes && (
            <LikeButton likes={likes} minified minifiedSize={17} color="blue" className="rounded-6 shadow-page-title" />
          )
        ) : (
          <Button
            className="group rounded-6 p-5 shadow-page-title hover:bg-new-blue-100/70 sm:p-8"
            aria-label="Like"
            onClick={() => {
              ui.authModal.setState(AUTH_MODAL_STATE.REGISTER_TO_CONTINUE, {
                // The only case when card header is used w/o shareId; shareType
                // Is front page (on blogpost cards)
                type: shareType || ENTITY_TYPES.BLOGPOST,
                id: shareId || blogpostId,
                action: REG_PROMPT_TRIGGERS.LIKE,
              });
              ui.authModal.setOpen(true);
            }}
          >
            <Icon icon="heart-empty" className="h-17 w-17 group-hover:text-white-100" />
          </Button>
        )}
        {editable && editFn && (
          <Button
            className="flex-shrink-0 rounded-6 p-10 text-13 shadow-page-title transition-colors hover:bg-new-blue-100/60 hover:text-white-100"
            onClick={editFn}
          >
            Edit
          </Button>
        )}
        {removable && (
          <Button
            className="flex-shrink-0 rounded-6 p-10 text-13 shadow-page-title transition-colors hover:bg-red-100 hover:text-white-100"
            onClick={async () => {
              if (!isDeleteRequested) {
                setDeleteRequested(true);
                return;
              }
              await blogposts.removeBlogpost(blogpostId);
              if (removableCb) removableCb();
            }}
          >
            {isDeleteRequested ? "Are you sure?" : <Icon icon="cross" className="h-13 w-13" />}
          </Button>
        )}
        {shareId && shareType && <ShareButtons entityId={shareId} entityType={shareType} />}
        {ownerProfile && user.isLoggedIn && profiles.currentUserProfile?.id !== ownerProfile.id && (
          <Button
            className={cn(
              "flex flex-shrink-0 items-center justify-center rounded-6 p-10 text-13 shadow-page-title transition-colors hover:text-white-100",
              ownerProfile.isFollowed ? "hover:bg-red-700" : "hover:bg-new-blue-100/60"
            )}
            onClick={
              ownerProfile.isFollowed
                ? () => profiles.currentUserProfile.unfollow(ownerProfile.id)
                : () => profiles.currentUserProfile.follow(ownerProfile.id)
            }
          >
            {ownerProfile.isFollowed ? "Unfollow" : "Follow"}
            <Icon
              icon={ownerProfile.isFollowed ? "person-minus" : "person-plus"}
              className="ml-7 h-12 w-12 fill-current"
            />
          </Button>
        )}
      </div>
      <div className="flex items-center space-x-10 md:hidden">
        {user.isLoggedIn ? (
          likes && (
            <LikeButton likes={likes} minified minifiedSize={15} color="blue" className="rounded-6 shadow-page-title" />
          )
        ) : (
          <Button
            className="group rounded-6 p-5 shadow-page-title hover:bg-new-blue-100/70 sm:p-8"
            onClick={() => {
              ui.authModal.setState(AUTH_MODAL_STATE.REGISTER_TO_CONTINUE, {
                // The only case when card header is used w/o shareId; shareType
                // Is front page (on blogpost cards)
                type: shareType || ENTITY_TYPES.BLOGPOST,
                id: shareId || blogpostId,
                action: REG_PROMPT_TRIGGERS.LIKE,
              });
              ui.authModal.setOpen(true);
            }}
          >
            <Icon icon="heart-empty" className="h-17 w-17 group-hover:text-white-100" />
          </Button>
        )}
        {editable && editFn && (
          <Button
            className="flex-shrink-0 rounded-6 px-10 py-6 text-13 shadow-page-title transition-colors hover:bg-new-blue-100/60 hover:text-white-100"
            onClick={editFn}
          >
            Edit
          </Button>
        )}
        {removable && (
          <Button
            className="flex-shrink-0 rounded-6 p-6 text-13 shadow-page-title transition-colors hover:bg-red-100 hover:text-white-100"
            onClick={async () => {
              await blogposts.removeBlogpost(blogpostId);
              if (removableCb) removableCb();
            }}
          >
            <Icon icon="cross" className="h-13 w-13" />
          </Button>
        )}
        {shareId && shareType && <ShareButtons entityId={shareId} entityType={shareType} minified />}
        {ownerProfile && user.isLoggedIn && profiles.currentUserProfile?.id !== ownerProfile.id && (
          <Button
            className={cn(
              "flex flex-shrink-0 items-center justify-center rounded-6 p-4 shadow-page-title transition-colors",
              ownerProfile.isFollowed ? "hover:text-red-700" : "hover:text-new-blue-100/60"
            )}
            onClick={
              ownerProfile.isFollowed
                ? () => profiles.currentUserProfile.unfollow(ownerProfile.id)
                : () => profiles.currentUserProfile.follow(ownerProfile.id)
            }
          >
            <Icon icon={ownerProfile.isFollowed ? "person-minus" : "person-plus"} className="h-17 w-17 fill-current" />
          </Button>
        )}
      </div>
      {/* <Popup popperOptions={popperOptions}>
        <Popup.Button className="px-10">
          <Icon icon="menu-dots" className="h-20 w-4 text-black-300 md:rotate-90" />
        </Popup.Button>
        <Popup.Content>
          <div className="flex min-w-[17.5rem] flex-col overflow-hidden rounded-6 bg-white-100 shadow-popup">
            {user?.userData?.id !== ownerProfile.id && (
              <Button className="w-full px-20 py-10 text-black-300 hover:bg-white-400">
                <Icon icon="eye-crossed" className="mr-10 h-15 w-15" />
                Ignore this user
              </Button>
            )}
            <Button
              className="w-full px-20 py-10 text-black-300 hover:bg-white-400 disabled:bg-white-200 disabled:text-gray-900"
              style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
            >
              <Icon icon="warning-circle" className="mr-10 h-15 w-15" />
              Report
            </Button>
          </div>
        </Popup.Content>
      </Popup> */}
    </div>
  );
}

CardHeader.propTypes = {
  ownerProfile: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    name: PropTypes.string,
    avatarFilename: PropTypes.string,
    isFollowed: PropTypes.bool,
  }),
  publishedAt: PropTypes.string,
  publishDateText: PropTypes.string,
  editable: PropTypes.bool,
  editFn: PropTypes.func,
  blogpostId: PropTypes.number,
  removable: PropTypes.bool,
  removableCb: PropTypes.func,
  isDraft: PropTypes.bool,
  pinned: PropTypes.bool,
  likes: PropTypes.shape(),
  shareId: PropTypes.number,
  shareType: PropTypes.oneOf(["artwork", "board", "blogpost", "collection"]),
};

CardHeader.defaultProps = {
  ownerProfile: null,
  publishedAt: null,
  publishDateText: null,
  editable: false,
  editFn: null,
  blogpostId: null,
  removable: false,
  removableCb: null,
  isDraft: false,
  pinned: false,
  likes: null,
  shareId: null,
  shareType: null,
};

export default observer(CardHeader);
