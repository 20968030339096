import { useCallback, useState } from "react";
import { Button, Icon } from "components/basic";
import PropTypes from "prop-types";

function LinkShare({ href, onShare }) {
  const [isCopiedToClipboard, setCopiedToClipboard] = useState(false);

  const handleShare = useCallback(async () => {
    await navigator.clipboard.writeText(href || window.location.href);
    onShare("clipboard");
    setCopiedToClipboard(true);
    setTimeout(() => setCopiedToClipboard(false), 2000);
  }, [href, onShare]);

  return isCopiedToClipboard ? (
    <Button className="pointer-events-none inline-flex min-h-38 items-center justify-start rounded-5 bg-purple-100 px-10 py-5 text-14 text-white-100">
      <Icon iconSet="socials" icon="globe" className="mr-10 h-22 w-22" />
      <span className="mr-auto mt-2">Copied to clipboard!</span>
    </Button>
  ) : (
    <Button
      className="inline-flex min-h-38 items-center justify-start rounded-5 bg-purple-300 px-10 py-5 text-14 text-white-100"
      onClick={handleShare}
    >
      <Icon iconSet="socials" icon="globe" className="mr-10 h-22 w-22" />
      <span className="mr-auto mt-2">Copy link</span>
    </Button>
  );
}

LinkShare.propTypes = {
  href: PropTypes.string,
  onShare: PropTypes.func.isRequired,
};

LinkShare.defaultProps = {
  href: null,
};

export default LinkShare;
