import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import Popup from "components/popups";
import cn from "classnames";

import ae from "lib/analytics";

import Icon from "components/basic/Icon";
import Ripples from "components/Ripples";
import FacebookShare from "./FacebookShare";
import TwitterShare from "./TwitterShare";
import TumblrShare from "./TumblrShare";
import LinkShare from "./LinkShare";

// DEBUG TOOLS
// facebook: developers.facebook.com/tools/debug
// twitter: cards-dev.twitter.com/validator

const popperOptions = {
  strategy: "fixed",
  placement: "bottom",
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        padding: 10,
      },
    },
    {
      name: "offset",
      options: {
        offset: [0, 10],
      },
    },
  ],
};

function ShareButtons({ minified, entityId, entityType }) {
  const trackShare = useCallback(
    type => {
      ae.share(type, entityId, entityType);
    },
    [entityId, entityType]
  );

  return (
    <Popup popperOptions={popperOptions}>
      {({ isOpen }) => [
        <Popup.Button className="flex-shrink-0">
          {minified ? (
            <Icon icon="share" className="h-25 w-25 rounded-6 p-5 shadow-page-title transition-transform" />
          ) : (
            <Ripples
              className={cn(
                "z-10 flex items-center rounded-6 p-10 text-13 shadow-page-title transition-colors hover:bg-new-blue-100/60 hover:text-white-100",
                isOpen ? "bg-new-blue-100/60 text-white-100" : null
              )}
            >
              <Icon icon="share" className="mr-5 h-13 w-15 transition-transform" />
              <span>Share</span>
            </Ripples>
          )}
        </Popup.Button>,
        <Popup.Content>
          <div className="flex min-w-[19.7rem] flex-col space-y-10 rounded-5 bg-white-700 p-10 text-14 text-black-100 shadow-auth">
            <FacebookShare onShare={trackShare} />
            <TwitterShare onShare={trackShare} />
            <TumblrShare onShare={trackShare} />
            <LinkShare onShare={trackShare} />
          </div>
        </Popup.Content>,
      ]}
    </Popup>
  );
}

ShareButtons.propTypes = {
  minified: PropTypes.bool,
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
};

ShareButtons.defaultProps = {
  minified: false,
};

export default observer(ShareButtons);
