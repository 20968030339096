import PropTypes from "prop-types";
import { Button, Icon } from "components/basic";

function TumblrShare({ href, onShare }) {
  function openInNewTab(url) {
    window.open(url, "blank").focus();
    onShare("tumblr");
  }

  return (
    <Button
      className="inline-flex min-h-38 items-center justify-start rounded-5 bg-blue-500 px-10 py-5 text-14 text-white-100"
      onClick={() => {
        openInNewTab(`http://www.tumblr.com/share/link?url=${href || window.location.href}`);
      }}
    >
      <Icon icon="tumblr" className="mr-10 h-22 w-22" />
      <span className="mr-auto mt-2">Share on Tumblr</span>
    </Button>
  );
}

TumblrShare.propTypes = {
  href: PropTypes.string,
  onShare: PropTypes.func.isRequired,
};

TumblrShare.defaultProps = {
  href: null,
};

export default TumblrShare;
