import PropTypes from "prop-types";
import { Button, Icon } from "components/basic";

function FacebookShare({ href, onShare }) {
  function openInNewTab(url) {
    window.open(url, "blank").focus();
    onShare("facebook");
  }

  return (
    <div data-href={href || window.location.href} data-layout="button" data-size="small">
      <Button
        className="inline-flex min-h-38 w-full items-center justify-start rounded-5 bg-blue-300 px-10 py-5 text-14 text-white-100"
        onClick={() => {
          openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${href || window.location.href}`);
        }}
      >
        <Icon icon="facebook" className="mr-10 h-22 w-22" />
        <span className="mr-auto mt-2">Share on Facebook</span>
      </Button>
    </div>
  );
}

FacebookShare.propTypes = {
  href: PropTypes.string,
  onShare: PropTypes.func.isRequired,
};

FacebookShare.defaultProps = {
  href: null,
};

export default FacebookShare;
